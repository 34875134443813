import { Box, Grid, Stack, Typography, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { Form, Formik, FormikHelpers } from "formik";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import SchemeManager from "../../managers/schemeManager";
import { FormPlaceholder } from "../../../../ui-library/placeholders/form-placeholder/FormPlaceholder";
import { schemeFormPlaceholderConfig } from "../../constants/CADocConstants";

const DefaultInitialValues = {
  leadCarry: "",
  roundType: "",
  hurdleRate: "",
  transactionFees: "",
  fundCarry: "",
  dealProgress: "",
  totalFundraising: "",
  preMoneyValuation: "",
  pricePerShare: "",
  portfolioEntityProfile: "",
  numberOfSecurities: "",
  conversionPriceTerms: "",
  investmentSchemeName: "",
  rightUnderNegotiation: "",
  lastConsentDate: "",
};

const NewSchemeGeneration: React.FC = () => {
  const [selectedVal, setSelectedVal] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>(DefaultInitialValues);
  const [loading, setLoading] = useState(false);
  const schemeManager = new SchemeManager();

  const fetchStartups = async (search: string) => {
    try {
      const result = await schemeManager.getAdminLookupData(
        "startups_as_per_tab",
        search,
        "new"
      );
      setOptions(result);
    } catch (error: any) {
      console.error(error);
    }
  };

  const fetchSchemeData = async (selected: any) => {
    try {
      if (selected?.value) {
        setLoading(true);
        const result = await schemeManager.getSchemeAndStartupData(
          selected.value
        );
        setInitialValues(result);
        setLoading(false);
      }
    } catch (error: any) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ values, errors }) => (
        <Form>
          <Stack gap={1} mb={3} width={"45vw"}>
            <LvAutoComplete
              label="Select Startup"
              options={options}
              setOptions={setOptions}
              selectedObject={selectedVal}
              setSelectedObject={setSelectedVal}
              onChange={fetchStartups}
              onSelect={fetchSchemeData}
            />
            {loading ? (
              <FormPlaceholder elementsConfig={schemeFormPlaceholderConfig} />
            ) : (
              selectedVal && (
                <>
                  <InputField
                    label="Total Lead Carry"
                    fieldName="leadCarry"
                    id="leadCarry"
                    name="leadCarry"
                    value={values.leadCarry}
                    error={!!errors.leadCarry}
                  />
                  <InputField
                    label="Round type"
                    fieldName="roundType"
                    id="roundType"
                    name="roundType"
                    value={values.roundType}
                    error={!!errors.roundType}
                    disabled
                  />
                  <InputField
                    label="Hurdle rate"
                    fieldName="hurdleRate"
                    id="hurdleRate"
                    name="hurdleRate"
                    value={values.hurdleRate}
                    error={!!errors.hurdleRate}
                  />
                  <InputField
                    label="Transaction fees"
                    fieldName="transactionFees"
                    id="transactionFees"
                    name="transactionFees"
                    value={values.transactionFees}
                    error={!!errors.transactionFees}
                  />
                  <InputField
                    label="Fund carry"
                    fieldName="fundCarry"
                    id="fundCarry"
                    name="fundCarry"
                    value={values.fundCarry}
                    error={!!errors.fundCarry}
                  />
                  <InputField
                    label="Deal progress on LV"
                    fieldName="dealProgress"
                    id="dealProgress"
                    name="dealProgress"
                    value={values.dealProgress}
                    error={!!errors.dealProgress}
                  />
                  <InputField
                    label="Total fundraising"
                    fieldName="totalFundraising"
                    id="totalFundraising"
                    name="totalFundraising"
                    value={values.totalFundraising}
                    error={!!errors.totalFundraising}
                  />
                  <InputField
                    label="Pre Money Valuation"
                    fieldName="preMoneyValuation"
                    id="preMoneyValuation"
                    name="preMoneyValuation"
                    value={values.preMoneyValuation}
                    error={!!errors.preMoneyValuation}
                  />
                  <InputField
                    label="Price per share/Unit of Security"
                    fieldName="pricePerShare"
                    id="pricePerShare"
                    name="pricePerShare"
                    value={values.pricePerShare}
                    error={!!errors.pricePerShare}
                  />
                  <InputField
                    label="Portfolio Entity profile and product details/services offered by the Portfolio Entity"
                    fieldName="portfolioEntityProfile"
                    id="portfolioEntityProfile"
                    name="portfolioEntityProfile"
                    value={values.portfolioEntityProfile}
                    error={!!errors.portfolioEntityProfile}
                  />
                  <InputField
                    label="Number of Securities"
                    fieldName="numberOfSecurities"
                    id="numberOfSecurities"
                    name="numberOfSecurities"
                    value={values.numberOfSecurities}
                    error={!!errors.numberOfSecurities}
                  />
                  <InputField
                    label="Conversion Price Terms"
                    fieldName="conversionPriceTerms"
                    id="conversionPriceTerms"
                    name="conversionPriceTerms"
                    value={values.conversionPriceTerms}
                    error={!!errors.conversionPriceTerms}
                  />
                  <InputField
                    label="Investment Scheme Name"
                    fieldName="investmentSchemeName"
                    id="investmentSchemeName"
                    name="investmentSchemeName"
                    value={values.investmentSchemeName}
                    error={!!errors.investmentSchemeName}
                    disabled
                  />
                  <InputField
                    label="Right under negotiation"
                    fieldName="rightUnderNegotiation"
                    id="rightUnderNegotiation"
                    name="rightUnderNegotiation"
                    value={values.rightUnderNegotiation}
                    error={!!errors.rightUnderNegotiation}
                  />
                  <InputField
                    type="date"
                    label="Last date to provide consent to participate in the investment scheme"
                    fieldName="lastConsentDate"
                    id="lastConsentDate"
                    name="lastConsentDate"
                    value={values.lastConsentDate}
                    error={!!errors.lastConsentDate}
                  />
                </>
              )
            )}
            {selectedVal && (
              <Stack direction="row" gap={2} mt={5}>
                <ActionButton type="submit">Save</ActionButton>
                <ActionButton type="button">Generate</ActionButton>
              </Stack>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default NewSchemeGeneration;
