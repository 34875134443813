import requestManager from "../../../api/requestManager";

const getAdminLookupData = (
  type: string,
  search: string = "",
  tab: string = ""
) => {
  const URL = `/v1/admin/lookups/get_lookup_data/?type=${type}&q=${search}&tab=${tab}`;
  return requestManager.apiGet(URL);
};

const getSchemeAndStartupData = (startup_id: any) => {
  const URL = `/v1/admin/startup_detail/get_startup_and_scheme_details/?startup_id=${startup_id}`;
  return requestManager.apiGet(URL);
};

export default { getAdminLookupData, getSchemeAndStartupData };
