import schemeServices from "../services/schemeServices";

export default class SchemeManager {
  async getAdminLookupData(status: string, search: string, tab: string) {
    try {
      let result: any = await schemeServices.getAdminLookupData(
        status,
        search,
        tab
      );
      result = result.data.results.map((item: any) => {
        return {
          label: item.text,
          value: item.id,
        };
      });
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getSchemeAndStartupData(startup_id: any) {
    try {
      if (!startup_id) {
        throw new Error("Startup ID is required");
      }

      const result = await schemeServices.getSchemeAndStartupData(startup_id);
      if (!result || !result.data) {
        throw new Error("Invalid response from schemeServices");
      }

      const {
        total_lead_carry,
        round_type,
        hurdle_rate,
        transaction_fees,
        fund_carry,
        funding_ask,
        pre_money_valuation,
        share_price_with_text,
        startup_service,
        securities_description,
        conversion_terms,
        scheme_name,
        last_date_to_consent,
        right_under_negotiations,
      } = result.data;

      return {
        leadCarry: total_lead_carry || 0,
        roundType: round_type || "",
        hurdleRate: hurdle_rate || 0,
        transactionFees: transaction_fees || 0,
        fundCarry: fund_carry || 0,
        dealProgress: "",
        totalFundraising: funding_ask || 0,
        preMoneyValuation: pre_money_valuation || 0,
        pricePerShare: share_price_with_text || "",
        portfolioEntityProfile: startup_service || "",
        numberOfSecurities: securities_description || "",
        conversionPriceTerms: conversion_terms || "",
        investmentSchemeName: scheme_name || "",
        rightUnderNegotiation: right_under_negotiations || "",
        lastConsentDate: last_date_to_consent || "",
      };
    } catch (error) {
      console.error("Error in getSchemeAndStartupData:", error);
      throw error;
    }
  }
}
