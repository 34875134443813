import axios from "axios";

const BASE_API_PATH = process.env.REACT_APP_DJANGO_BASE_URL;
const BASE_RUBY_API_PATH = process.env.REACT_APP_API_BASE_URL;

function getHeaders() {
  return { "Content-Type": "application/json" };
}

function apiGet(url: string) {
  const finalUrl: string = BASE_API_PATH + url;
  return axios.get(finalUrl, { headers: getHeaders() });
}

function apiPost(
  url: string,
  body: object,
  headers: any = {},
  redirectToRuby = false
) {
  const BASE_PATH = redirectToRuby ? BASE_RUBY_API_PATH : BASE_API_PATH;
  const finalUrl: string = BASE_PATH + url;
  return axios.post(finalUrl, body, {
    headers: Object.keys(headers).length ? headers : getHeaders(),
  });
}

function apiPut(url: string, body: object, headers: any = {}) {
  const finalUrl: string = BASE_API_PATH + url;
  return axios.put(finalUrl, body, {
    headers: Object.keys(headers).length ? headers : getHeaders(),
  });
}

function apiDelete(url: string) {
  const finalUrl: string = BASE_API_PATH + url;
  return axios.delete(finalUrl, { headers: getHeaders() });
}

function apiDeleteWithBody(url: string, data: object) {
  const finalUrl: string = BASE_API_PATH + url;
  return axios({
    url: finalUrl,
    method: "delete",
    responseType: "json",
    withCredentials: true,
    headers: getHeaders(),
    data,
  });
}

export default {
  getHeaders,
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  apiDeleteWithBody,
};
